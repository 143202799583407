$ccWhite: #f3e1a1;
$ccBlue: #90cef3;
$ccBlack: #897f93;
$ccRed: #f9917c;
$ccGreen: #82c38b;

.cherry {
    background-color: #fae4e8;
}

.lime {
    background-color: #dff0be;
}

.teal {
    background-color: #d0eeef;
}

.first-bg {
    background: linear-gradient(45deg, #faca1d, #967f00);
}

.second-bg {
    background: linear-gradient(45deg, #ededed, #bdbdbd);
}

.ccWhiteBack {
    background-color: $ccWhite;
}

.ccBlueBack {
    background-color: $ccBlue;
}

.ccBlackBack {
    background-color: $ccBlack;
}

.ccRedBack {
    background-color: $ccRed;
}

.ccGreenBack {
    background-color: $ccGreen;
}

.back50Lighten {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgb(255, 255, 255, 1), rgb(255, 255, 255, 0.75));
}

.cc {
    height: 100vh;
    display: flex;
    flex-direction: column;
    > :nth-last-child(2) {
      flex-grow: 1;
    }
  
    .imageFit {
      object-fit: contain;
    }

    .iconSmallest {
        height: 20px;
        max-height: 20px;
        width: auto;
        object-fit: contain;
    }
    
    .iconTiny {
      height: 50px;
      max-height: 50px;
      width: auto;
      object-fit: contain;
    }
  
    .iconSmall {
      height: 100px;
      max-height: 100px;
      width: auto;
      object-fit: contain;
    }
    
    .iconMedium {
      height: 200px;
      max-height: 200px;
      width: auto;
      object-fit: contain;
    }
    
    .iconLarge {
      height: 300px;
      max-height: 300px;
      width: auto;
      object-fit: contain;
    }
    
    .navDropdown > .dropdown-menu {
      margin-top: 0px !important; /* Override reactstrap spacing to allow hover dropdown */
    }
  
    .nav-item.active {
      border-bottom: 2px solid rgba(255, 255, 255, 0.55);
    }
  
    .staffImg {
      margin-top: 20px;
      width: 200px;
      border-radius: 50%;
      z-index: 1;
    }
  
    .staffCardUpper {
      position: absolute;
      height: 120px;
      width: 100%;
      top: 0px;
      left: 0px;
      border-radius: 5px 5px 0px 0px; /* Don't clip the card's top border radius */
    }
  
    .testimonialTextArea {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
  
      > div {
        border-radius: 5px;
        padding: 2px;
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  
    .hotelBadge {
      text-align: center;
      width: 30px;
      height: 30px;
      flex: 0 0 auto;
      border-radius: 50%;
      border: 1px solid white;
      color: white;
      background-color: purple;
    }
  
    .link-card:hover {
      background-color: lightgray;
      box-shadow: 0 0 5px gray;
    }
  
    .card {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    }
  
    .y-grow {
      min-height: 100%;
    }
  }
  
  .body {
      background-color: rgb(230, 230, 230);
  }
  
  .timeline {
    position: relative;
  
    &::before {
      background-color: gray;
      position: absolute;
      left: 8px;
      top: 0px;
      width: 4px;
      height: 100%;
      content: '';
    }
  
    .timelineItem {
      display: flex;
      margin: 10px 0px;
    }
  
    .timelineItemContents {
      padding-left: 2px;
    }
  
    .timelineArea {
      padding-top: 3px;
      z-index: 1;
      display: flex;
      flex-direction: column;
    }
  
    .timelineCircle {
      flex: 0 0 auto;
      background-color: white;
      border: 3px solid gray;
      border-radius: 50%;
      width: 20px;
      height: 20px;
  
      &._timelineCircleFill {
          background-color: gray;
      }
  
      &._timelineCircleEnd {
          background-color: red;
      }
    }
  
    .timelineItem:last-child .timelineArea::after {
      width: 100%;
      flex: 1 1 auto;
      display: block;
      background: white;
      content: '';
    }
  }